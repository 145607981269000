import { Button } from "../../common/Button/styled";
import Heading from "../../common/Heading";
import skillset from "./skillset";
import { SkillsContainer, SkillsSection, SkillsContentWrapper } from "./styled";

const Skills = () => {
  return (
    <SkillsSection id="skills">
      <SkillsContainer>
        <Heading title="Skills" />
        {skillset.map(data => (
          <SkillsContentWrapper key={data.id}>
            {data.items.map(item => (
              <Button key={item.id} chip>
                {item.name}
              </Button>
            ))}
          </SkillsContentWrapper>
        ))}
      </SkillsContainer>
    </SkillsSection>
  );
};

export default Skills;
