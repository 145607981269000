import Heading from "../../common/Heading";
import {
  PortfolioHeadingContainer,
  PortfolioHeading,
  PortfolioParagraph,
  PortfolioSection,
  PortfolioContainer,
  StyledIcon,
  Wrapper,
  ChipWrapper,
} from "./styled";
import { StyledLink } from "./styled";
import portfolio from "./portfolio";
import { Button } from "../../common/Button/styled";

const Portfolio = () => {
  return (
    <PortfolioSection id="projects">
      <PortfolioHeadingContainer>
        <Heading title="Projects" />
      </PortfolioHeadingContainer>
      <PortfolioContainer>
        {portfolio.map(item => (
          <Wrapper key={item.id}>
            <PortfolioHeading>{item.title}</PortfolioHeading>
            <ChipWrapper>
              {item.technologies.map(data => (
                <Button key={data.id} secondaryChip>
                  {data.name}
                </Button>
              ))}
            </ChipWrapper>
            <PortfolioParagraph>{item.description}</PortfolioParagraph>
            <PortfolioParagraph>
              <StyledIcon />
              <StyledLink
                to={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </StyledLink>
            </PortfolioParagraph>
          </Wrapper>
        ))}
      </PortfolioContainer>
    </PortfolioSection>
  );
};

export default Portfolio;
