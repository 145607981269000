const portfolio = [
  {
    id: 1,
    title: "Movies Browser",
    description:
      "Movies Browser is an application for browsing, searching movies using an API. It provides quick access to detailed information about films, such as cast, descriptions, ratings.",
    link: "https://q270311.github.io/movies-browser/#/movies-list",
    technologies: [
      { id: 2, name: "React" },
      { id: 3, name: "Redux" },
      { id: 4, name: "Styled-components" },
      { id: 5, name: "REST API" },
    ],
  },
  {
    id: 6,
    title: "To Do List",
    description:
      "A to do list application that lets users add, delete, search tasks. It also provides sample tasks. You can also see the author information in another tab.",
    link: "https://panijoanna.github.io/to-do-list-React/",
    technologies: [
      { id: 7, name: "React" },
      { id: 8, name: "Redux" },
      { id: 9, name: "Styled-components" },
    ],
  },
  {
    id: 10,
    title: "Płytowisko",
    description:
      "Płytowisko is an e-commerce platform for vinyl record enthusiasts. Users can search, buy, and sell records that interest them.",
    link: "https://www.facebook.com/plytowisko",
    technologies: [
      { id: 11, name: "Angular" },
      { id: 12, name: "NestJS" },
      { id: 13, name: "TypeScript" },
      { id: 14, name: "PostgreSQL" },
      { id: 15, name: "GraphQL" },
    ],
  },
];

export default portfolio;
