import { HeadingContainer, StyledHeading } from "./styled";

const Heading = ({ title }) => {
  return (
    <HeadingContainer>
      <StyledHeading>{title}</StyledHeading>
    </HeadingContainer>
  );
};

export default Heading;
