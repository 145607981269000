import styled from "styled-components";

export const SkillsSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 100px;
  transition: ${({ theme }) => theme.transitions.secondaryTransform};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding: 0 30px;
    margin-top: 20px;
    height: auto;
  }
`;

export const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

export const SkillsContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px 0;
  gap: 15px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    margin: 20px 0;
  }
`;
