import styled from "styled-components";
import { ReactComponent as Arrow } from "../../assets/arrow-right.svg";

export const StyledFooter = styled.footer`
  display: flex;
  width: 90%;
  margin: auto;
  padding: 120px 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding: 30px;
    margin: 20px 0 0 0;
  }
`;

export const FooterContainer = styled.div``;

export const ArrowIcon = styled(Arrow)`
  transform: rotate(-45deg);
`;

export const StyledWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
`;
