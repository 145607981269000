import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Icon } from "../../assets/link-icon.svg";

export const PortfolioSection = styled.section`
  width: 100%;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding: 0 30px;
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.link};
`;

export const PortfolioHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding-bottom: 0;
  }
`;

export const PortfolioContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    grid-template-columns: 1fr;
  }
`;

export const PortfolioHeading = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: 29px;
  letter-spacing: 0.05em;
  font-weight: ${({ theme }) => theme.fontWeights.large};
  margin: 0;
  padding-bottom: 10px;
`;

export const PortfolioParagraph = styled.p`
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.color.primaryHighlightedText};
  line-height: 25px;
  letter-spacing: 0.05em;
  margin: 0;
  gap: 5px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: 140%;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.color.link};
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  border: 5px solid ${({ theme }) => theme.color.borderTile};
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 4px;
  transition: 0.4s;

  &:hover {
    border: 5px solid ${({ theme }) => theme.color.tileHover};
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding: 15px;
  }
`;

export const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  cursor: default;
`;

export const Container = styled.div``;
