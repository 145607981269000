const common = {
  breakpoint: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 767,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560,
  },
  fontWeights: {
    regular: 400,
    regularPlus: 500,
    medium: 600,
    large: 700,
    bold: 900,
  },
  fontSizes: {
    xxsmall: "12px",
    default: "14px",
    xsmall: "16px",
    small: "18px",
    medium: "20px",
    large: "24px",
    xlarge: "30px",
    xxlarge: "38px",
    heading: "45px",
  },
  transitions: {
    transform: "transform 0.5s ease",
    secondaryTransform: "0.4s",
  },
};

export const themeLight = {
  ...common,
  color: {
    background: "#fbfbfe",
    primaryText: "#030712",
    button: "#030712",
    primaryHighlightedText: "#959595",
    secondaryHighlightedText: "#aeb2ba",
    chipText: "#4f5154",
    chipBackground: "#EBF1FD",
    secondaryChipBackground: "#030712",
    highlightedHover: "#c7c7c7",
    switcher: "#0A0A0A",
    tile: "#d9d9d9",
    borderTile: "#f2f3f4",
    tileHover: "#f8f9fa",
    highlightedBorder: "#F7F7F7",
    link: "#3492fa",
  },
};

export const themeDark = {
  ...common,
  color: {
    background: "#222222",
    primaryText: "#FAFAFA",
    button: "#FFF083",
    primaryHighlightedText: "#c2c2c2",
    secondaryHighlightedText: "#c2c2c2",
    chipText: "#FAFAFA",
    chipBackground: "#3E3E3E",
    secondaryChipBackground: "#c2c2c2",
    highlightedHover: "#c7c7c7",
    switcher: "#0A0A0A",
    tile: "#2A2A2A",
    borderTile: "#484848",
    tileHover: "#727272",
    highlightedBorder: "#4d4d4d",
    link: "#FFF083",
  },
};
