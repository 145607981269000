import styled from "styled-components";
import { animated } from "react-spring";
import { ReactComponent as Icon } from "../../assets/arrow-right.svg";

export const StyledHeader = styled(animated.header)`
  display: flex;
  padding: 100px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}px) {
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

export const HeaderImage = styled.img`
  border-radius: 50%;
  margin-bottom: 0;
  display: flex;
  max-width: 300px;
  height: auto;
  transition: ${({ theme }) => theme.transitions.transform};

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    width: 60%;
  }
`;

export const StyledParagraph = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.large};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  line-height: 130%;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
  }
`;

export const HeaderTitle = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  line-height: 46px;
  letter-spacing: 0.05em;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const HeaderContentContainer = styled.div`
  padding: 20px 0 40px 66px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoint.laptop}px) {
    align-items: center;
    padding: 30px 0 0 0;
  }
`;

export const ArrowIcon = styled(Icon)``;
