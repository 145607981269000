import {
  NavigationItem,
  StyledLink,
  NavigationList,
  StyledNavigation,
  NavigationText,
  NavigationTextWrapper,
} from "./styled";
import ThemeSwitcher from "./../ThemeSwitcher/index";

const Navigation = () => {
  return (
    <StyledNavigation>
      <NavigationTextWrapper>
        <NavigationText secondary>port</NavigationText>
        <NavigationText>folio.</NavigationText>
      </NavigationTextWrapper>
      <NavigationList>
        <NavigationItem>
          <StyledLink href="#home">Home</StyledLink>
        </NavigationItem>
        <NavigationItem>
          <StyledLink href="#skills">Skills</StyledLink>
        </NavigationItem>
        <NavigationItem>
          <StyledLink href="#projects">Projects</StyledLink>
        </NavigationItem>
        <StyledLink href="#contact">Contact</StyledLink>
        <ThemeSwitcher />
      </NavigationList>
    </StyledNavigation>
  );
};

export default Navigation;
