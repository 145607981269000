import styled, { css } from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";

export const ThemeButton = styled.button`
  border: 1px solid ${({ theme }) => theme.color.primaryHighlightedText};
  width: 47px;
  height: 25px;
  border-radius: 30px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    margin: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  display: flex;
  color: ${({ theme }) => theme.color.switcher};
  transition: ${({ theme }) => theme.transitions.transform};
  background-color: ${({ theme }) => theme.color.themeBackground};
  border-radius: 50%;
  width: 18px;
  height: 18px;

  ${({ currenttheme }) =>
    currenttheme === "dark" &&
    css`
      transform: translateX(18px);
    `}
`;

export const ThemeSwitcherContainer = styled.div``;
