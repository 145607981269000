const skillset = [
  {
    id: 1,
    title: "Frameworks",
    items: [
      { id: 1, name: "Vue" },
      { id: 2, name: "Angular" },
      { id: 3, name: "Vitest" },
      { id: 4, name: "Next.js" },
      { id: 5, name: "NestJS" },
      { id: 6, name: "JavaScript" },
      { id: 7, name: "TypeScript" },
      { id: 8, name: "HTML5" },
      { id: 9, name: "CSS3" },
      { id: 10, name: "GraphQL" },
      { id: 11, name: "REST API" },
      { id: 12, name: "Accessibility" },
      { id: 13, name: "RWD" },
      { id: 14, name: "Tailwind CSS" },
      { id: 15, name: "Material UI" },
      { id: 16, name: "Styled-components" },
      { id: 17, name: "Figma" },
      { id: 18, name: "Bootstrap" },
      { id: 19, name: "React" },
      { id: 20, name: "Redux" },
      { id: 21, name: "Pinia" },
      { id: 22, name: "PostgreSQL" },
    ],
  },
];

export default skillset;
