import styled from "styled-components";

export const StyledParagraph = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  line-height: 46px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.color.heading};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

export const UppercaseParagraph = styled(StyledParagraph)`
  font-weight: ${({ theme }) => theme.fontWeights.regularPlus};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  color: ${({ theme }) => theme.color.primaryHighlightedText};
  line-height: 130%;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
  }
`;

export const EmailParagraph = styled(StyledParagraph)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  line-height: 39px;
  letter-spacing: 0.05em;
  width: 30px;
  margin: 0;
  transition: 0.3s;

  &:hover {
    color: ${({ theme }) => theme.color.link};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobileDevice}px) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const SecondaryParagraph = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    width: auto;
    max-width: 500px;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoint.tablet}) and (max-width: ${({ theme }) =>
      theme.breakpoint.laptop}px) {
    font-size: 14px;
    width: 200px;
  }
`;

export const StyledItem = styled.span``;
