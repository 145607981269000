import { useContext } from "react";
import { ThemeButton, ThemeSwitcherContainer, StyledIcon } from "./styled";
import { ThemeContext } from "styled-components";
import { useState } from "react";

const ThemeSwitcher = () => {
  const { theme, themeToggler } = useContext(ThemeContext);
  const [isDark, setIsDark] = useState(false);

  const handleClick = () => {
    themeToggler();
    setIsDark(prev => !prev);
  };

  return (
    <ThemeSwitcherContainer>
      <ThemeButton onClick={handleClick}>
        {isDark ? (
          <StyledIcon
            icon="fluent:weather-sunny-16-filled"
            currenttheme={theme}
          />
        ) : (
          <StyledIcon
            icon="fluent:weather-moon-20-filled"
            currenttheme={theme}
          />
        )}
      </ThemeButton>
    </ThemeSwitcherContainer>
  );
};

export default ThemeSwitcher;
