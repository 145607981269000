import styled from "styled-components";

export const StyledHeading = styled.h2`
  color: ${({ theme }) => theme.color.primaryText};
  font-size: ${({ theme }) => theme.fontSizes.heading};
  margin: 0;
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;
