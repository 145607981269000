import styled, { css } from "styled-components";

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px 20px;
  gap: 5px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  background: ${({ theme }) => theme.color.button};
  color: ${({ theme }) => theme.color.background};
  border: none;
  border-radius: 25px;
  margin-top: 25px;
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:hover {
    background: ${({ theme }) => theme.color.highlightedHover};
    transition: all 0.5s ease;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding: 8px 12px;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      background: none;
      color: ${({ theme }) => theme.color.primaryText};
      border: 1px solid ${({ theme }) => theme.color.button};

      &:hover {
        background: none;
        color: ${({ theme }) => theme.color.highlightedHover};
      }
    `}

  ${({ chip }) =>
    chip &&
    css`
      color: ${({ theme }) => theme.color.chipText};
      background: ${({ theme }) => theme.color.chipBackground};
      cursor: default;
      max-width: 250px;
      padding: 6px 20px;
      margin: 0;
      font-size: ${({ theme }) => theme.fontSizes.xsmall};

      &:hover {
        background: ${({ theme }) => theme.color.chipBackground};
      }
    `}

     ${({ secondaryChip }) =>
    secondaryChip &&
    css`
      background: none;
      color: ${({ theme }) => theme.color.primaryText};
      border: 1px solid ${({ theme }) => theme.color.secondaryChipBackground};
      padding: 4px 10px;
      font-size: ${({ theme }) => theme.fontSizes.default};
      margin-top: 0;

      &:hover {
        background: none;
        cursor: default;
      }
    `}
`;
