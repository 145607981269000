import styled, { css } from "styled-components";

export const StyledNavigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
  height: 13vh;
  background: ${({ theme }) => theme.color.background};
  padding: 20px 80px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 2px;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding: 0 20px;
    height: 10vh;
    justify-content: center;
  }
`;

export const NavigationList = styled.ul`
  display: flex;
  gap: 20px;
  list-style-type: none;

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    padding: 0;
    gap: 10px;
  }
`;

export const NavigationItem = styled.li`
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-weight: 300;

  &:hover {
    color: ${({ theme }) => theme.color.highlightedHover};
    transition: ${({ theme }) => theme.transitions.transform};
  }
`;

export const NavigationText = styled.span`
  font-family: "Bebas Neue", sans-serif;
  font-weight: 800;
  font-size: 22px;
  letter-spacing: 0.5px;

  ${color =>
    color.secondary &&
    css`
      color: ${({ theme }) => theme.color.secondaryHighlightedText};
    `}

  @media (max-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    display: none;
  }
`;

export const NavigationTextWrapper = styled.div``;

export const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: 300;
  font-size: 16px;

  &:hover {
    color: ${({ theme }) => theme.color.highlightedHover};
    transition: ${({ theme }) => theme.transitions.transform};
  }
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: 40px;
  cursor: pointer;
`;
